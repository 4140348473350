import { Box, Button } from "@mui/joy";
import ProgressBar from "../shared/ProgressBar";
import { Modal, Sheet, Typography, ModalDialog } from "@mui/joy";
import Arrow from "../../assets/images/Frame.svg";

import LogoGreen from "../../assets/images/logoGreen.svg";
import { useEffect, useState } from "react";



const ImageContainer = ({ progressValue, step, formData }: any) => {

  const navigateToLinkedIn = () => {
    window.location.href =
    "https://www.linkedin.com/company/aware-org/";
  }
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };
  const [drinkingResult, setDrinkingResult] = useState("");



  useEffect(() => {
    const safeDrinkingGuideLine = () => {
      const gender = formData.genderStep;
      const size = parseInt(formData.drinkSizeStep);
      const schedule = formData.drinkScheduleStep;
      const numberOfDrinks = parseInt(formData.howOftenStep);

      let totalDrinks = 0;

      let numberOfDrinkPerSize = 0;

      switch (size) {
        case 330:
          numberOfDrinkPerSize = 1;
          break;
        case 440:
          numberOfDrinkPerSize = 1.33;
          break;
        case 750:
          numberOfDrinkPerSize = 2.25;
          break;
        case 1000:
          numberOfDrinkPerSize = 3.03;
          break;
        case 125:
          numberOfDrinkPerSize = 1;
          break;
        case 250:
          numberOfDrinkPerSize = 1;
          break;
        case 300:
          numberOfDrinkPerSize = 2;
          break;
        case 750.1:
          numberOfDrinkPerSize = 6;
          break;
        case 25:
          numberOfDrinkPerSize = 1;
          break;
        case 50:
          numberOfDrinkPerSize = 2;
          break;
        case 750.2:
          numberOfDrinkPerSize = 30;
          break;
        case 0.5:
          numberOfDrinkPerSize = 1;
          break;
        case 1:
          numberOfDrinkPerSize = 2;
          break;
        case 2:
          numberOfDrinkPerSize = 4;
          break;
      }

      switch (schedule) {
        case "Daily":
          totalDrinks = numberOfDrinks * 7 * numberOfDrinkPerSize;
          break;
        case "3 Times a week":
          totalDrinks = numberOfDrinks * 3 * numberOfDrinkPerSize;
          break;
        case "5 Times a week":
          totalDrinks = numberOfDrinks * 5 * numberOfDrinkPerSize;
          break;
        case " Once a week":
          totalDrinks = numberOfDrinks * numberOfDrinkPerSize;
          break;
        case " Once a month":
          totalDrinks = (numberOfDrinks / 31) * numberOfDrinkPerSize;
          break;
        case " Once a year":
          totalDrinks = (numberOfDrinks / 365) * numberOfDrinkPerSize;
          break;
      }

      const checkTotalDrinks = (totalDrinks: number) => {
        let dailyDrink = totalDrinks / 7;
        if (gender === "Male") {
          if (dailyDrink <= 7 && totalDrinks <= 14) {
            setDrinkingResult("Low Risk");
          } else {
            setDrinkingResult("High risk");
          }
        } else {
          if (dailyDrink <= 5 && totalDrinks <= 14) {
            setDrinkingResult("Low Risk");
          } else {
            setDrinkingResult("High risk");
          }
        }
      };

      checkTotalDrinks(totalDrinks);
    };
    safeDrinkingGuideLine();
  }, [
    formData.howOftenStep,
    formData.drinkScheduleStep,
    formData.genderStep,
    formData.drinkSizeStep,
  ]);

  const resultsChecker = () => {
    if (drinkingResult == "High risk") {
      return (
        <Typography
          sx={{
            color: "#40A2AD",
            fontFamily: "Manrope",
            fontSize: { xs: "22px", lg: "40px" },
            fontWeight: "700",
          }}
        >
          Some adjustment needed
        </Typography>
      );
    } else if (drinkingResult == "Low Risk") {
      return (
        <Typography
          sx={{
            color: "#40A2AD",
            fontFamily: "Manrope",
            fontSize: { xs: "22px", lg: "40px" },
            fontWeight: "700",
          }}
        >
          On the right track{" "}
        </Typography>
      );
    } else {
      return null;
    }
  };

  const buttonDisplay = () => {
    if (drinkingResult == "High risk") {
      return (
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "row",
            gap: { xs: "0", lg: "0" },
            justifyContent: "flex-between",
          }}
        >
          <Button
          onClick={navigateToLinkedIn}
            sx={{
              background: "#FF3A18",
              borderRadius: "77.4px",
              color: "#FFF",
              ":hover": {
                background: "transparent",
                border: "1px solid #FFF",
              },
            }}
            type="submit"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                gap: "20px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ textTransform: "none" }}>
                  Find out more{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignSelf: "center", flexGrow: "3" }}>
                <img src={Arrow} alt="/" />
              </Box>
            </Box>
          </Button>
        </Box>
      );
    } else {
      return (
        <Button
        onClick={navigateToLinkedIn}

          variant="plain"
          sx={{
            display: { xs: "none", lg: "flex" },
            background: "#FF3A18",
            borderRadius: "77.4px",
            color: "#FFF",
            ":hover": {
              background: "transparent",
              border: "1px solid #FFF",
            },
          }}
          type="submit"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyItems: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ flexGrow: "1" }}>
              <Typography sx={{ textTransform: "none" }}>
                Find out more{" "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignSelf: "center", flexGrow: "3" }}>
              <img src={Arrow} alt="/" />
            </Box>
          </Box>
        </Button>
      );
    }
  };
  const displayHeading = () => {
    if (step === 0) {
      return "What is your age?";
    }
    if (step === 1) {
      return "What is your sex?";
    } else if (step === 2) {
      return "What are your goals?";
    } else if (step === 3) {
      return "What is your weight?";
    } else if (step === 4) {
      return "Do you mostly drink alone or with friends?";
    } else if (step === 5) {
      return "Do you mostly drink at home or out and about?";
    } else if (step === 6) {
      return "Your drink of choice?";
    } else if (step === 7) {
      return "What is your standard drink size?";
    } else if (step === 8) {
      return "How many do you usually have at one time?";
    } else if (step === 9) {
      return " How often do you drink this way? ";
    } else {
      return "Your results";
    }
  };

  const displayText = () => {
    if (step === 0) {
      return "Alcohol affects people of different ages uniquely due to their body's processes. Share your age for personalised advice, ensuring safety, health, and responsible enjoyment.";
    }
    if (step === 1) {
      return "Understanding your sex helps us tailor our services to your unique needs, ensuring a personalised survey experience that works for you.";
    } else if (step === 2) {
      return "Your aspirations are a defining part of you. By understanding your goals, we can help you see how alcohol may be affecting your ideal future.";
    } else if (step === 3) {
      return "Your weight impacts how alcohol affects your body. Sharing your weight helps us provide tailored insights for your body type.";
    } else if (step == 4) {
      return "Your input helps us tailor resources for responsible choices, whether solo or social.";
    } else if (step === 5) {
      return "Revealing where you consume alcohol enriches our insights for better guidance. Whether it's cosy nights in or outings.";
    } else if (step === 6) {
      return "Understanding your preferred drink empowers us to curate content and advice that resonate with your tastes";
    } else if (step === 7) {
      return "Knowing your typical drink size helps us offer personalised insights into your consumption patterns, promoting mindful choices.";
    } else if (step === 8) {
      return "Whether it's a glass or more, understanding your intake enables us to offer guidance for moderation and well-being.";
    } else if (step === 9) {
      return "Knowing how often you consume your standard alcohol units at once helps us provide personalised insights.";
    } else {
      return "";
    }
  };

  const displayProgress = () => {
    if (step == 11) {
      return (
        <Box sx={{ display: "none" }}>
          <ProgressBar value={progressValue} />
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "block" }}>
          <ProgressBar value={progressValue} />
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        background: "#363D49",
        borderTopLeftRadius: { xs: "18px", lg: "18px" },
        borderTopRightRadius: { xs: "18px", lg: "0" },

        borderBottomLeftRadius: { xs: 0, lg: "18px" },

        height:
          step !== 11
            ? { xs: "255px", lg: "100%" }
            : { xs: "auto", lg: "100%" },

        padding: { xs: "10px 23px 0 23px", lg: "0px 23px 30px 23px" },
        boxShadow: "0px 0px 11px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: step !== 11 ? { xs: "20px", lg: "0" } : { xs: "0", lg: "0" },
        }}
      >
        <Box sx={{ position: "relative" }}>
          {step != 11 ? (
            <Typography
              sx={{
                position: { xs: "absolute", lg: "relative" },
                marginTop: { xs: "-45px", lg: "0" },
                marginLeft: { xs: "-25px", lg: "0" },
                color: "rgba(174, 174, 174, 0.50)",
                fontFamily: "Manrope",
                fontSize: { xs: "116px", lg: "50px" },
                fontWeight: "700",
                height: { xs: "0", lg: "120px" },
                paddingTop: { xs: "0", lg: "20px" },
                opacity: { xs: "0.05", lg: "1" },
              }}
            >
              {step == 11 ? "" : `Q${step + 1}`}
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "rgba(174, 174, 174, 0.50)",
                fontFamily: "Manrope",
                fontSize: "50px",
                fontWeight: "700",
              }}
            >
              {step == 11 ? "" : `Q${step + 1}`}
            </Typography>
          )}
        </Box>

        <Box>
          {step != 11 ? (
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Manrope",
                fontSize: { xs: "18px", lg: "30px" },
                fontWeight: "700",
                minHeight: { xs: "46px", lg: "130px" },
              }}
            >
              {displayHeading()}
            </Typography>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Manrope",
                  fontSize: { xs: "22px", lg: "30px" },
                  fontWeight: "700",
                  paddingBottom: "20px",
                  paddingTop: { xs: "0", lg: "30px" },
                }}
              >
                {displayHeading()}
              </Typography>
              <Box sx={{ display: { xs: "block", lg: "none" } }}>
                {step < 10 ? displayProgress() : buttonDisplay()}
              </Box>
            </Box>
          )}
        </Box>
        {step == 11 && (
          <Box sx={{ height: { xs: "0", lg: "78px" } }}>{resultsChecker()}</Box>
        )}
        <Box>
          {step != 11 ? (
            <Typography
              sx={{
                color: "#AEAEAE",
                fontWeight: "400",
                fontFamily: "Manrope",
                height: { xs: "0", lg: "200px" },
                fontSize: { xs: "14px", lg: "18px" },
              }}
            >
              {displayText()}
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "#AEAEAE",
                fontWeight: "400",
                fontFamily: "Manrope",
                paddingBottom: { xs: "10px", lg: "50px" },
                fontSize: { xs: "14px", lg: "18px" },
              }}
            >
              If you drink less than 14 units of alcohol per week, you follow
              the safe Chief Medical Officers' guidelines. <br />
              <br />
              However, exceeding these guidelines by regularly consuming 14 or
              more units per week, or drinking six or more units in one session
              (for females) or eight or more units (for males), can seriously
              harm your health. Please be aware of these risks.
            </Typography>
          )}
        </Box>

        <Box sx={{ paddingTop: "15px", width: "100%" }} className="transitions">
          <Box>
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={openModal}
              onClose={() => setOpenModal(false)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <ModalDialog
                layout="fullscreen"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Sheet
                  variant="plain"
                  sx={{
                    maxWidth: 500,
                    borderRadius: "18px",
                    p: 3,
                    boxShadow: "lg",
                    backgroundColor: "#363D49",
                    gap: "25px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "40px",
                    }}
                  >
                    <img src={LogoGreen} alt="/" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        color: "#FFF",
                        fontSize: { xs: "25px", lg: "32px" },
                        fontWeight: "700",
                        textAlign: "center",
                        paddingBottom: "40px",
                      }}
                    >
                      Thanks for submitting.{" "}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      color: "#FFF",
                      fontSize: { xs: "14px", lg: "18px" },
                      fontWeight: "500",
                      textAlign: "center",

                      paddingBottom: "40px",
                    }}
                  >
                    Your input helps us direct our resources where needed most.
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Manrope",
                      color: "#FFF",
                      fontSize: { xs: "14px", lg: "18px" },
                      fontWeight: "500",
                      paddingBottom: "40px",
                      textAlign: "center",
                    }}
                  >
                    You can close this tab and head back to our website.{" "}
                  </Typography>

                  <Typography
                    sx={{ paddingBottom: "40px", textAlign: "center" }}
                  >
                    <a
                      href="https://aware.org.za/"
                      style={{
                        fontFamily: "Manrope",
                        color: "#FF3A18",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      www.aware.org.za
                    </a>
                  </Typography>
                </Sheet>
              </ModalDialog>
            </Modal>
          </Box>
          <Box>
            {step < 11 ? (
              <Box sx={{ display: { xs: "block", lg: "block" } }}>
                {displayProgress()}
              </Box>
            ) : (
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                {buttonDisplay()}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageContainer;
